import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sinoferta',
  templateUrl: './sinoferta.component.html',
  styleUrls: ['./sinoferta.component.css']
})
export class SinofertaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
