<p-card>
    <div class="p-grid">
        <div class="p-field p-col-6">
            <div class="p-d-flex">
                <div class="card">
                    <i class="pi pi-envelope" style="font-size: 2rem"></i>
                </div>
                <span class="texto">jmartinezvalles@gmail.com</span>
            </div>

            <div class="p-d-flex">
                <div class="card">
                    <i class="pi pi-home" style="font-size: 2rem"></i>
                </div>
                <span class="texto">Escalante 15, Burjassot, Valencia</span>
            </div>

            <div class="p-d-flex">
                <div class="card">
                    <i class="pi pi-mobile" style="font-size: 2rem"></i>
                </div>
                <span class="texto">605 49 86 63</span>
            </div>
        </div>

        <div class="p-col-6">
            <form (submit)="onSendMessage(messageForm)" #messageForm="ngForm">
                <input type="text" 
                    placeholder="Nombre" 
                    pInputText 
                    class="areatexto" 
                    name="nombre"
                    ngModel
                    required/>
                <input type="text" 
                    class="p-inputtext-lg" 
                    placeholder="Correo electrónico" 
                    pInputText 
                    class="areatexto" 
                    name="email"
                    ngModel
                    required
                    email/>
                <input type="text" 
                    class="p-inputtext-lg" 
                    placeholder="Asunto" 
                    pInputText 
                    class="areatexto" 
                    name="asunto"
                    ngModel
                    required/>
                <textarea rows="5" cols="30" 
                    pInputTextarea 
                    autoResize="autoResize" 
                    placeholder="Mensaje"
                    class="areatexto"
                    name="mensaje"
                    ngModel
                    required>
                </textarea>
                <button pButton pRipple type="submit" label="Enviar" class="p-button-raised"
                [disabled]="!messageForm.valid" *ngIf="!enviado"></button>
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem" *ngIf="enviado"></i>
            </form>
        </div>
    </div>
</p-card>