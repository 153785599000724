import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  enviado: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onSendMessage(form: NgForm) {
    this.enviado = true;
    this.anula();
  }

  anula () {
    setTimeout(() => {
      this.enviado = false;
    }, 2000);
  }

}
