import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfertasService {

  // url='http://localhost:8080/back/data.php/';  /// Contenedor CV
  // url = 'https://cv-jmv.eu-gb.mybluemix.net/back/data.php/';  /// IBM Cloud. Contenedor PRUEBA
  url = environment.urlBack;

  constructor(private http: HttpClient) { }

    recuperaOferta(oferta: Object) {
      return this.http.post(this.url + '?show', JSON.stringify(oferta));
    }

    listaApuntadas() {
      return this.http.get(this.url + '?allinall');
    }

    eliminaOferta(oferta: Object) {
      return this.http.post(this.url + '?del', JSON.stringify(oferta));
    }

    guardaOferta(oferta: Object) {
      return this.http.post(this.url + '?add', JSON.stringify(oferta));
    }
}
