import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';

import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PanelModule } from 'primeng/panel';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from "primeng/card";
import { CarouselModule } from 'primeng/carousel';
import { RatingModule } from 'primeng/rating';
import { KnobModule } from 'primeng/knob';
import { AvatarModule } from 'primeng/avatar';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RippleModule } from 'primeng/ripple';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';

import { PresentacionComponent } from './curriculum/presentacion/presentacion.component';
import { ExperienciaComponent } from './curriculum/experiencia/experiencia.component';
import { TrabajosComponent } from './curriculum/trabajos/trabajos.component';
import { EstudiosComponent } from './curriculum/estudios/estudios.component';
import { ContactoComponent } from './curriculum/contacto/contacto.component';
import { AdminComponent } from './admin/admin.component';
import { CurriculumComponent } from './curriculum/curriculum.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { DescargaComponent } from './curriculum/descarga/descarga.component';
import { SinofertaComponent } from './curriculum/sinoferta/sinoferta.component';


const appRoutes: Routes = [
  { path: '', component: NotfoundComponent },
  { path: '404', component: NotfoundComponent },
  { path: 'jadmin', component: AdminComponent },
  { path: ':oferta', component: CurriculumComponent }
]

@NgModule({
  declarations: [
    AppComponent,
    PresentacionComponent,
    ExperienciaComponent,
    TrabajosComponent,
    EstudiosComponent,
    ContactoComponent,
    AdminComponent,
    CurriculumComponent,
    NotfoundComponent,
    DescargaComponent,
    SinofertaComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ButtonModule,
    SelectButtonModule,
    PanelModule,
    TimelineModule,
    CardModule,
    CarouselModule,
    RatingModule,
    KnobModule,
    AvatarModule,
    InputTextModule,
    InputTextareaModule,
    RippleModule,
    AccordionModule,
    DialogModule,
    TooltipModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
