import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trabajos',
  templateUrl: './trabajos.component.html',
  styleUrls: ['./trabajos.component.scss']
})
export class TrabajosComponent implements OnInit {

  trabajos: any[];

  constructor() { }

  ngOnInit(): void {
    this.trabajos = [
      {
        imagen: 'condesa.jpg', 
        nombre: 'CONDESA',
        descripcion: 'Control y despliegue de aplicaciones',
        largo: 'Hecha exclusivamente para aplicaciones Oracle forms, permite la descarga, compilación y despliegue desde repositorios SVN, de aplicaciones completas o módulos individuales.',
        iconos: ['html5','css3','jquery','jenkins','php']
      },
      {
        imagen: 'admin.jpg', 
        nombre: 'CONDESA ADMIN',
        descripcion: 'Administración de repositorios SVN',
        largo: 'Aplicación realizada para la administración de repositorios SVN y de los permisos de acceso a ellos.',
        iconos: ['html5','css3','jquery','perl']
      },
      {
        imagen: 'gamesdb.jpg', 
        nombre: 'GAMESDB',
        descripcion: 'Base de datos de videojuegos',
        largo: 'Realizada para uso personal, consulta un api público de videojuegos (api.thegamesdb.net) para el mantenimiento de una bbdd de videojuegos y consolas propios.',
        iconos: ['mongodb','express','angular','nodejs','docker','ibm cloud']
      },
      {
        imagen: 'mustache.jpg', 
        nombre: 'MUSTACHE BOY',
        descripcion: 'Clon del juego arcade Mustache Boy',
        largo: 'Clon del juego arcade Mustache Boy para dispositivos móviles programado en C# con el motor de videojuegos Unity.',
        iconos: ['c','unity']
      },
      {
        imagen: 'phoozle.jpg', 
        nombre: 'PHOOZLE',
        descripcion: 'Juego de puzzles',
        largo: 'Juego de puzzles para dispositivos móviles programado en C# con el motor de videojuegos Unity.',
        iconos: ['c','unity']
      },
      {
        imagen: 'donkeyKong.jpg', 
        nombre: 'DONKEY KONG',
        descripcion: 'Clon del juego de la portátil Game & Watch Donkey Kong',
        largo: 'Clon del juego de Game & Watch Donkey Kong. para dispositivos móviles programado en Lua con el motor de videojuegos Corona SDK.',
        iconos: ['corona','lua']
      },
      {
        imagen: 'donkeyKongJr.jpg', 
        nombre: 'DONKEY KONG JR.',
        descripcion: 'Clon del juego de la portátil Game & Watch Donkey Kong',
        largo: 'Clon del juego de Game & Watch Donkey Kong. para dispositivos móviles programado en Lua con el motor de videojuegos Corona SDK.',
        iconos: ['corona','lua']
      },
      {
        imagen: 'dcddgtic.png', 
        nombre: 'DCDDGTIC',
        descripcion: 'Diccionario Centralizado de Datos de la DGTIC',
        largo: 'Aplicación que recopila, almacena y muestra datos relativos al funcionamiento de la DGTIC (rendimientos de BBDD, despliegues de aplicaciones y configuraciones de servidores).',
        iconos: ['html5','bootstrap','jquery','php','oracle']
      }
    ];
  }

}
