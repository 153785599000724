import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OfertasService } from '../ofertas.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  apuntadas: any;
  insert: any;
  erase: any;

  ofertaAlta = {
    codigo: null,
    empresa: null,
    puesto: null,
    url: null
  }

  ofertaBaja = {
    codigo: null
  }

  // Mensajse de error
  cabeceraError: string;
  muestraError: boolean;
  textoError: string;

  constructor(private ofertasServ: OfertasService, private router: Router) { }

  ngOnInit(): void {
    this.refrescaLista();
  }

  refrescaLista() {
    this.ofertasServ.listaApuntadas().subscribe(
      result => {
        this.apuntadas = result;
      }
    );
  }

  /// CODIGO RELACIONADO CON LA INTRODUCCION DE UNA OFERTA
  generaCodigo () {
    this.ofertaAlta.codigo = Math.random().toString(36).substring(7);
  }

  guardaOferta () {
    if ((this.ofertaAlta.codigo == undefined) || (this.ofertaAlta.empresa == undefined)
     || (this.ofertaAlta.puesto == undefined) || (this.ofertaAlta.url == undefined)) {
      this.cabeceraError = "Error";
      this.textoError = "Revisa los datos que quieres almacenar. Algo falta.";
      this.muestraError = true;
    } else { 
      this.ofertasServ.guardaOferta(this.ofertaAlta).subscribe(result => {
          this.insert = result;
          if (this.insert.resultado == 0) {
            this.refrescaLista();
            this.cabeceraError = "Exito";
            this.textoError = this.insert.mensaje;
            this.muestraError = true;
          } else {
            this.cabeceraError = "Error insertando datos";
            this.textoError = this.insert.mensaje;
            this.muestraError = true;
          }
      });
    }
    
  }

  eliminaOferta(oferta: string) {
    this.ofertaBaja.codigo = oferta;

    this.ofertasServ.eliminaOferta(this.ofertaBaja).subscribe(result => {
      this.erase = result;
      if (this.erase.resultado == 0) {
        this.refrescaLista();
        this.cabeceraError = "Oferta borrada";
        this.textoError = this.erase.mensaje;
        this.muestraError = true;
      } else {
        this.cabeceraError = "Error eliminando datos";
        this.textoError = this.erase.mensaje;
        this.muestraError = true;
      }
    })
  }

  verOferta(oferta: string) {
    //console.log(oferta);
    this.router.navigate(['', oferta ]);
  }

}
