<div class="card">
    <p-timeline [value]="events1" align="alternate" styleClass="customized-timeline">
        <ng-template pTemplate="marker" let-event>
            <span class="custom-marker p-shadow-2" [style.backgroundColor]="event.color">
                <i [ngClass]="event.icon"></i>
            </span>
        </ng-template>
        <ng-template pTemplate="content" let-event >
            <p-card [header]="event.status" [subheader]="event.date">
                <img *ngIf="event.image" [src]="'assets/showcase/images/demo/product/' + event.image" [alt]="event.name" width="200" class="p-shadow-2" />
                <p class="texto">
                    {{ event.text }}
                    <a href="{{ event.url }}" target="_blank">{{ event.enlace }}</a>
                    {{ event.texto2 }}
                </p>
            </p-card>
        </ng-template>
    </p-timeline>
</div>