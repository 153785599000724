<p-carousel [value]="trabajos" [numVisible]="1" [numScroll]="1" [circular]="true" [page]="7" [showIndicators]="false">
    <ng-template let-product pTemplate="item">
        <div class="product-item">
            <div class="product-item-content">
                <div class="p-mb-3">
                    <img src="../../assets/{{product.imagen}}" [alt]="product.nombre" class="product-image" />
                </div>
                <div>
                    <h4 class="p-mb-1">{{product.nombre}}</h4>
                    <h6 class="p-mt-0 p-mb-3">{{product.descripcion}}</h6>
                    <p-avatar *ngFor="let icono of product.iconos" image="../../assets/{{ icono }}.png" 
                        pTooltip="{{ icono }}" tooltipPosition="bottom"
                        styleClass="p-mr-2 p-mb-3"></p-avatar>
                    <!--p class="p-mt-0 p-mb-3">{{product.largo}}</p>-->
                    <p class="descripcion">{{product.largo}}</p>
                </div>
            </div>
        </div>
    </ng-template>
</p-carousel>