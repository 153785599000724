import { Component, OnInit } from '@angular/core';
import {PrimeIcons} from 'primeng/api';


@Component({
  selector: 'app-experiencia',
  templateUrl: './experiencia.component.html',
  styleUrls: ['./experiencia.component.scss']
})
export class ExperienciaComponent implements OnInit {

  events1: any[];

  constructor() { }

  ngOnInit(): void {
    this.events1 = [
      {
        status: 'Técnico DevOps y administrador de sistemas', 
        date: 'Septiembre 2022 - Actualidad', 
        icon: PrimeIcons.GITHUB, color: '#f9bf3f',
        text: 'Técnico DevOps, administrador de sistemas y de bases de datos en ',
        url: 'https://grupooesia.com/',
        enlace: 'Grupo Oesia',
        texto2: '. Prestando servicios en los Ayuntamientos de Valladolid y Zaragoza y para el Ministerio de Justicia. Automatizaciones de despliegues de aplicaciones (GitLab, Jenkins), instalaciones de sistemas (docker, clusters swarm y kubernetes) y administración y mantenimiento de sistemas (weblogic, docker, oracle, jira, jenkins, sonarqube, nexus, ...)',
      },
      {
        status: 'Técnico DevOps', 
        date: 'Septiembre 2021 - Agosto 2022', 
        icon: PrimeIcons.CHECK_CIRCLE, color: '#2fa499',
        text: 'Técnico de calidad y DevOps en la empresa ',
        url: 'https://www.excentia.es/',
        enlace: 'Excentia',
        texto2: '. Realizando el análisis y mantenimiento de la calidad del código de aplicativos de diversos clientes (SonarQube), actualizaciones de software y mantenimiento y revisión de despliegues (Jenkins).',
      },
      {
        status: 'Responsable de programación y despliegues contínuos', 
        date: 'Marzo 2013 - Agosto 2021', 
        icon: PrimeIcons.SITEMAP, color: '#9C27B0',
        text: 'Responsable del área de programación y despliegues continuos del departamento de sistemas de la ',
        url: 'https://dgtic.gva.es/es/home',
        enlace: 'Dirección General de Tecnologías de la Información',
        texto2: '. También realizando labores de soporte y mantenimiento de Bases de Datos (oracle, postgress, mysql) y servidores de aplicaciones (tomcat, jboss, apache, liferay), programación de tareas automatizadas en shell scripting y de despliegue continuo con Jenkins. Desarrollo de aplicaciones a medida usando los lenguajes html, css, javascript, jquery, perl y php.',
      },
      {
        status: 'Administrador de bases de datos', 
        date: 'Septiembre 2008 - Febrero 2013', 
        icon: PrimeIcons.BARS, color: '#673AB7',
        text: 'Administrador de Bases de Datos y servidores de aplicaciones (Oracle 9.x, 10.x y 11.x) de la aplicación ',
        url: 'https://www.san.gva.es/es/web/tarjeta-sanitaria/sistema-de-informacion-poblacional-sip',
        enlace: 'SIP',
        texto2: ' (tarjetas sanitarias europeas). También realizando labores de programación de componentes de la aplicación en PL/SQL.'
      },
      {
        status: 'Desarrollador', 
        date: 'Agosto 2007 - Agosto 2008', 
        icon: PrimeIcons.DESKTOP, color: '#FF9800',
        text: 'Programador de la aplicación ',
        url: 'https://www.addinformatica.com/',
        enlace: 'Resiplus',
        texto2: ' utilizando los lenguajes Visual Basic y .Net sobre bases de datos SQL Server.'
      },
      {
        status: 'Técnico informático', 
        date: 'Diciembre 2006 - Julio 2007', 
        icon: PrimeIcons.COG, color: '#607D8B',
        text: 'Técnico informático de Bases de Datos. También realizando labores de atención a usuarios del ERP ',
        url: 'https://www.icg.es/soluciones/gestion-empresarial/',
        enlace: 'ICG Manager',
        texto2: '. Instalación, reparación y configuración de equipos informáticos, servidores y periféricos. Soporte a clientes y mantenimiento e instalación de redes. Programación de bases de datos en Access y Visual Basic .Net en entornos Cliente/Servidor, administración de bases de datos Microsoft SQL Server 2005.',
      },
      {
        status: 'Técnico informático', 
        date: 'Diciembre 2005 - Octubre 2006',
        icon: PrimeIcons.MOBILE, color: '#2fa499',
        text: 'Técnico informático de soporte local y atención al usuario final en la empresa ',
        url: 'https://www.orange.es/',
        enlace: 'Orange',
        texto2: '.'
      },
      {
        status: 'Programador y administrador de bases de datos', 
        date: 'Noviembre 2000 - Diciembre 2005',
        icon: PrimeIcons.DESKTOP, color: '#2c98f0',
        text: 'Técnico y administrador de bases de datos y de sistemas Windows NT/2000/2003/XP en el ',
        url: 'https://www.112cv.gva.es/es/inicio',
        enlace: 'Centro de Coordinación de Emergencias de la Comunidad Valenciana',
        texto2: '. Programación de bases de datos en Access y Visual Basic en entornos Cliente/Servidor. Realización páginas web en intranet con javascript y java sobre bases de datos.'
      },
      {
        status: 'Técnico de mantenimiento', 
        date: 'Junio 1999 - Noviembre 2000',
        icon: PrimeIcons.COG, color: '#f9bf3f',
        text: 'Técnico de mantenimiento de la plataforma ',
        url: 'https://carmenta.com/en/public-safety/carmenta-coordcom/medical-fire-and-police-dispatching/',
        enlace: 'Coordcom',
        texto2: ' en el Centro de Coordinación de Emergencias de la Comunidad Valenciana. También realizando labores de administración de sistemas Windows NT, HP-Unix, sistemas de posicionamiento GIS, e integración de comunicaciones de alta tecnología con sistemas CTI.'
      }
  ];
  }

}
