<div class="p-grid box p-shadow-8" style="margin: 10px; background-color: ghostwhite;">
    <h5 class="cabecera">Insertar oferta</h5>
    <div class="p-grid" style="width: 100%; margin: .5rem;">
        <!-- FORMULARIO DE ENTRADA / EDICION -->
            <div class="p-col-4" style="display: inline-block;">
                <label for="codigo" class="texto">C&oacute;digo</label>
                <input id="codigo" type="text" pInputText [(ngModel)]="ofertaAlta.codigo" style="margin-right: .5rem; width: 65%">
                <button pButton pRipple type="button" label="Genera c&oacute;digo" class="p-button-raised p-button-secondary"
                    (click)="generaCodigo()"></button>
            </div>

            <div class="p-col-4">
                <label for="empresa" class="texto">Empresa</label>
                <input id="empresa" type="text" pInputText [(ngModel)]="ofertaAlta.empresa" style="margin-right: .5rem; width: 100%;">
            </div>

            <div class="p-col-4">
                <label for="puesto" class="texto">Puesto</label>
                <input id="puesto" type="text" pInputText [(ngModel)]="ofertaAlta.puesto" style="margin-right: .5rem; width: 100%;">
            </div>

            <div class="p-col-12">
                <label for="puesto" class="texto">Url de la oferta</label>
                <input id="url" type="text" pInputText [(ngModel)]="ofertaAlta.url" style="margin-right: .5rem; width: 100%;">
            </div>

            <div class="p-col-12">
                <button pButton pRipple type="button" label="Guardar" class="p-button-raised" style="width: inherit;"
                    (click)="guardaOferta()"></button>
            </div>
    </div>
</div>

<!-- OFERTAS APUNTADAS -->
<div class="p-grid box p-shadow-8" style="margin: 10px; background-color: ghostwhite;">
    <h5 class="cabecera">Ofertas a las que est&aacute;s apuntado</h5>
    <div class="p-grid" style="width: 100%; margin: .5rem;">

        <p-accordion [multiple]="true" style="width: 96vw;">
            <p-accordionTab header="{{ oferta.puesto }}" *ngFor="let oferta of apuntadas">
                <div class="p-grid" style="width: 100%; margin: .5rem;">
                    <div class="p-col-6">
                        {{ oferta.empresa }}
                    </div>
                    <div class="p-col-6" style="text-align: end;">
                        {{ oferta.fecha_alta }}
                    </div>
                    <div class="p-col-10">
                        <a href="{{ oferta.url }}" target="_blank">{{ oferta.url }}</a>
                    </div>
                    <div class="p-col-2" style="text-align: end;">
                        <button pButton pRipple type="button" icon="pi pi-eye" class="p-button-rounded p-button-success p-button-text" (click)="verOferta(oferta.codigo);"></button>
                        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text" (click)="eliminaOferta(oferta.codigo);"></button>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>

    </div>
</div>

<!-- MENSAJES -->
<p-dialog header="{{ cabeceraError }}" [(visible)]="muestraError">
    {{ textoError }}
</p-dialog>