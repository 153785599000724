<div class="p-grid" style="margin: 10px;">
    <div class="p-col-12 box p-shadow-8" style="background-color: ghostwhite;;">
        <!-- FOTO -->
        <div class="p-col-12" style="text-align: center;">
            <img src="../assets/foto.png" style="width: 10%;margin-top: -7em;">
        </div>

        <!-- NOMBRE -->
        <div class="p-col-12" style="text-align: center;">
            <div class="p-text-center p-text-capitalize p-text-bold nombre">Jorge Mart&iacute;nez Vall&eacute;s</div>
            <div class="p-text-center p-text-uppercase dedicacion">T&eacute;cnico y Desarrollador Inform&aacute;tico</div>
        </div>

        <!-- PRESENTACION -->
        <div class="p-col-12" style="text-align: center;">
            <app-sinoferta></app-sinoferta>
        </div>

        <!-- BOTONETS -->
        <div class="p-col-12" style="text-align: center;">
            <p-selectButton [options]="opciones" [(ngModel)]="seleccion" optionLabel="icon" optionValue="parte">
                <ng-template let-item>
                    <i [class]="item.icon" class="boton">
                        <p class="p-text-center p-text-uppercase quick">{{ item.parte }}</p>       
                    </i>
                </ng-template>
            </p-selectButton>
        </div>

        <!-- CHICHA -->
        <div class="p-grid">
            <div class="p-col-2" style="text-align: center;">
                <div *ngFor="let izq of conocimientos; let i = index">
                    <div *ngIf="i % 2 === 0">
                        <p class="conoc">{{ izq.texto }}</p>
                        <p-knob [(ngModel)]="izq.valor" [readonly]="true" [size]="80" valueTemplate="{value}%" valueColor="{{ izq.color }}"></p-knob>
                    </div>
                </div>
            </div>
            <div class="p-col-8 datos">
                <app-experiencia *ngIf="seleccion=='experiencia'"></app-experiencia>
                <app-trabajos *ngIf="seleccion=='trabajos'"></app-trabajos>
                <app-estudios *ngIf="seleccion=='estudios'"></app-estudios>
                <app-contacto *ngIf="seleccion=='contacto'"></app-contacto>
                <app-descarga *ngIf="seleccion=='descarga'"></app-descarga>
            </div>
            <div class="p-col-2" style="text-align: center;">
                <div *ngFor="let der of conocimientos; let i = index">
                    <div *ngIf="i % 2 === 1">
                        <p class="conoc">{{ der.texto }}</p>
                        <p-knob [(ngModel)]="der.valor" [readonly]="true" [size]="80" valueTemplate="{value}%" valueColor="{{ der.color }}"></p-knob>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <p class="leyenda">
        Web hecha en <a href="https://angular.io/">Angular</a> con la librer&iacute;a <a href="https://www.primefaces.org/primeng/">PrimeNG</a> y servida a trav&eacute;s de <a href="https://www.docker.com/">Docker</a>.
    </p>
</div>