<div class="p-grid">
    <div class="p-col-2"></div>
    <div class="p-col">
        <p-panel header="PRESENTACIÓN"  [toggleable]="true">
            <p class="textopres p-text-justify">
                Soy <b>Jorge Martínez</b> y quería presentarme y agradecerle que visite mi página web personal desarrollada en modo currículum vitae '<em>interactivo</em>'.
            </p>
            <p class="textopres p-text-justify">
                Durante mis años de experiencia profesional he estado especialmente motivado con el desarrollo, 
                integración y despliegue automático de software, campos que me han permitido aplicar mis diseños y 
                conocimientos a los requerimientos especificados con gran solvencia. 
                Soy ingeniero informático por la Universidad de Valencia, he trabajado  en diferentes campos de la 
                informática, y me considero una persona tenaz, inquieta por seguir aprendiendo, 
                con espíritu de sacrificio y con muy buena disposición para trabajar en equipo.
            </p>
            <p class="textopres p-text-justify">
                Quedo a su disposición para una conversación con usted, a su mejor conveniencia, para exponer de manera
                personal la información que se recoge en esta página.
            </p>
            <p class="textopres p-text-justify">
                A la espera de sus noticias, le saluda atentamente,<br>
                <b>Jorge Martínez</b>
            </p>
        </p-panel>
    </div>
    <div class="p-col-2"></div>
</div>