<div class="p-grid">
    <div class="p-col-2"></div>
    <div class="p-col">
        <p-panel header="PRESENTACI&Oacute;N"  [toggleable]="true">
            <p class="textopres p-text-justify">
                Soy <b>Jorge Martínez</b> y he tenido ocasión de ver la oferta publicada en InfoJobs en el que solicitan un 
                <b>{{ datos.puesto }}</b> para <b>{{ datos.empresa }}</b>.
            </p>
            <p class="textopres p-text-justify">
                Durante mis años de experiencia profesional he estado especialmente motivado con el desarrollo e integración
                de software, campo que me ha permitido aplicar mis diseños y conocimientos a los requerimientos
                especificados con gran solvencia. Es por ello que considero estar capacitado para cubrir el puesto que
                solicitan. Soy ingeniero informático por la Universidad de Valencia, he trabajado en diferentes campos de la
                informática, y me considero una persona tenaz, inquieta por seguir aprendiendo, con espíritu de sacrificio y
                con muy buena disposición para trabajar en equipo.
            </p>
            <p class="textopres p-text-justify">
                Quedo a su disposición para una conversación con usted, a su mejor conveniencia, para exponer de manera
                personal la información que se recoge en esta página.
            </p>
            <p class="textopres p-text-justify">
                A la espera de sus noticias, le saluda atentamente,<br>
                <b>Jorge Martínez</b>
            </p>
        </p-panel>
    </div>
    <div class="p-col-2"></div>
</div>