import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { OfertasService } from '../ofertas.service';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.css']
})
export class CurriculumComponent implements OnInit {

  opciones: any[];
  conocimientos: any[];

  seleccion: string;
  oferta = {
    codigo: null
  }
  show: any;

  // lo que se muestra en la presentacion
  datos = {
    empresa: null,
    puesto: null
  }

  constructor(private route: ActivatedRoute, private router: Router, private ofertasServ: OfertasService) { 
    this.opciones = [
      {icon: 'pi pi-book', justify: 'Left', parte: 'estudios'},
      {icon: 'pi pi-briefcase', justify: 'Right', parte: 'experiencia'},
      {icon: 'pi pi-cog', justify: 'Center', parte: 'trabajos'},
      {icon: 'pi pi-user', justify: 'Justify', parte: 'contacto'},
      {icon: 'pi pi-download', justify: 'Justify', parte: 'descarga'}
    ];

    this.conocimientos = [
      {texto: 'Inglés', color: '#2c98f0', valor: 60},
      {texto: 'Valenciano', color: '#ec5453', valor: 50},
      {texto: 'HTML5', color: '#f9bf3f', valor: 85},
      {texto: 'CSS3', color: '#a84cb8', valor: 80},
      {texto: 'jQuery', color: '#2fa499', valor: 70},
      {texto: 'PHP', color: '#4054b2', valor: 75},
      {texto: 'Git', color: '#ec5453', valor: 65},
      {texto: 'Subversion', color: '#2c98f0', valor: 65},
      {texto: 'Serv. apps.', color: '#a84cb8', valor: 75},
      {texto: 'Admon. Sist.', color: '#f9bf3f', valor: 65},
      {texto: 'Jenkins', color: '#4054b2', valor: 80},
      {texto: 'Oracle', color: '#2fa499', valor: 70},
    ]
  }

  ngOnInit(): void {
    this.oferta.codigo = this.route.snapshot.params['oferta'];

    this.ofertasServ.recuperaOferta(this.oferta).subscribe(result => {
      this.show = result;
      if (this.show.length == 2) {
        this.datos.empresa = this.show[1];
        this.datos.puesto = this.show[0];
      } else {
        //console.log("No hay oferta con ese codigo");
        this.router.navigate(['/404']);
      }
  });
  }

}
