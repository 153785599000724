import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {

  opciones: any[];
  conocimientos: any[];

  seleccion: string;
  
  constructor() {

    this.opciones = [
      {icon: 'pi pi-book', justify: 'Left', parte: 'estudios'},
      {icon: 'pi pi-briefcase', justify: 'Right', parte: 'experiencia'},
      {icon: 'pi pi-cog', justify: 'Center', parte: 'trabajos'},
      {icon: 'pi pi-user', justify: 'Justify', parte: 'contacto'},
      {icon: 'pi pi-download', justify: 'Justify', parte: 'descarga'}
    ];

    this.conocimientos = [
      {texto: 'Inglés', color: '#2c98f0', valor: 60},
      {texto: 'SonarQube', color: '#ec5453', valor: 80},
      {texto: 'CI/CD', color: '#f9bf3f', valor: 85},
      {texto: 'Docker', color: '#a84cb8', valor: 75},
      {texto: 'GitLab', color: '#ec5453', valor: 75},
      {texto: 'Subversion', color: '#2c98f0', valor: 65},
      {texto: 'Serv. apps.', color: '#a84cb8', valor: 75},
      {texto: 'Admon. Sist.', color: '#f9bf3f', valor: 65},
      {texto: 'Jenkins', color: '#4054b2', valor: 80},
      {texto: 'Oracle', color: '#2fa499', valor: 70},
    ]
   }

  ngOnInit(): void {
  }

}
