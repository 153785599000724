import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-presentacion',
  templateUrl: './presentacion.component.html',
  styleUrls: ['./presentacion.component.css']
})
export class PresentacionComponent {

  @Input() datos: {empresa: string, puesto: string};

  constructor() { }

}
